:root {
  --max-vw: 1440;
  --min-vw: 600;
  --max-fs: 1;
  --min-fs: .75;
  --min-fs-rem: calc(var(--min-fs) * 1rem);
  --max-fs-rem: calc(var(--max-fs) * 1rem);
  --min-vw-rem: calc((var(--min-vw) / 10) * 1rem);
  --slope: calc((var(--max-fs)  - var(--min-fs)) * (100vw - var(--min-vw-rem)) / ((var(--max-vw) / 10)  - (var(--min-vw) / 10)));
  --font-size-clamp: clamp(var(--min-fs-rem), calc(var(--min-fs-rem)  + var(--slope)), var(--max-fs-rem));
  --line-height-base: 1.5;
  --line-height-heading: 1.3em;
}

html {
  font-feature-settings: "kern" 1, "liga" 0;
  text-rendering: optimizelegibility;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-size: 10px;
}

body {
  font-family: var(--font-family-base, "Inter");
  font-size: var(--font-size-clamp);
  line-height: var(--line-height-base);
  font-weight: 400;
}

a {
  -webkit-text-decoration-skip-ink: auto;
  text-decoration-skip-ink: auto;
}

.display-large {
  font-size: 5.7em;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.08em;
}

.display-medium {
  font-size: 4.5em;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.07em;
}

.display-small {
  font-size: 3.6em;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.07em;
}

.headline-large {
  font-size: 3.2em;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.06em;
}

.headline-medium {
  font-size: 2.8em;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.06em;
}

.headline-small {
  font-size: 2.4em;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.05em;
}

.title-large {
  font-size: 2.2em;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.04em;
}

.title-medium {
  font-size: 1.6rem;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.03em;
  font-weight: 600;
}

.title-small {
  font-size: 1.4rem;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  font-weight: 600;
}

.label-large {
  text-wrap: balance;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2rem;
}

.label-medium {
  text-wrap: balance;
  margin-bottom: .8rem;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.6rem;
}

.label-small {
  text-wrap: balance;
  margin-bottom: .8rem;
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1.6rem;
}

.body-large {
  text-wrap: balance;
  margin-bottom: 1.2rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.body-medium {
  text-wrap: balance;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  line-height: 2rem;
}

.body-small {
  text-wrap: balance;
  margin-bottom: .8rem;
  font-size: 1.2rem;
  line-height: 1.6rem;
}

ul, ol {
  padding-left: 0;
  font-size: 1.4rem;
  line-height: 2rem;
  list-style-position: outside;
}

:where(:not(html, iframe, canvas, img, svg, video, audio):not(svg *, symbol *)) {
  all: unset;
  display: revert;
}

*, :before, :after {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
}

a, button {
  cursor: revert;
}

ol, ul, menu, summary {
  list-style: none;
}

img {
  max-block-size: 100%;
  max-inline-size: 100%;
}

table {
  border-collapse: collapse;
}

input, textarea {
  -webkit-user-select: auto;
}

textarea {
  white-space: revert;
}

meter {
  appearance: revert;
}

:where(pre) {
  all: revert;
  box-sizing: border-box;
}

::placeholder {
  color: unset;
}

:where([hidden]) {
  display: none;
}

:where([contenteditable]:not([contenteditable="false"])) {
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  overflow-wrap: break-word;
  -webkit-line-break: after-white-space;
  -webkit-user-select: auto;
}

:where([draggable="true"]) {
  -webkit-user-drag: element;
}

:where(dialog:modal) {
  all: revert;
  box-sizing: border-box;
}

::-webkit-details-marker {
  display: none;
}

:root {
  --z-index-header: 1;
  --c-white: #fff;
  --max-vw: 1600;
  --min-vw: 600;
  --max-fs: 1;
  --min-fs: .6;
  --font-family-base: "Inter", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --line-height-base: 1.5;
  --line-height-heading: 1.3em;
  --c-scrollbar-track: Red;
  --c-scrollbar-thumb: Blue;
  --c-scrollbar-hover: Green;
  --v-offset-sm: min(50px, 3vmax);
  --v-offset-md: min(100px, 5vmax);
  --v-offset-lg: min(160px, 8vmax);
}

@media (width >= 992px) {
  :root {
    --v-offset-sm: min(40px, 2vmax);
    --v-offset-md: min(80px, 4vmax);
  }
}

html, body {
  position: relative;
}

body {
  width: 100vw;
  overflow: hidden auto;
}

@supports (min-height: 100dvh) {
  body {
    min-height: 100dvh;
  }
}

@supports not (min-height: 100dvh) {
  body {
    min-height: 100vh;
  }
}

body::-webkit-scrollbar {
  opacity: 0;
  width: 12px;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--c-scrollbar-thumb, var(--c-canary-yellow));
  border: 4px solid var(--c-scrollbar-track, var(--c-blue));
  border-radius: 2px;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: var(--c-scrollbar-hover, var(--c-text-greenish-yellow));
}

body::-webkit-scrollbar-track {
  background-color: var(--c-scrollbar-track, var(--c-blue));
}

.display-large {
  color: #a9a9a9;
  margin: 20px 0 0;
  font-weight: 900;
}

.page {
  background-color: #f5f5f5;
  place-content: center;
  justify-items: center;
  width: min(1140px, 100% - 40px);
  min-height: 100vh;
  margin: auto;
  display: grid;
}

.svg-icon--logo-volta {
  aspect-ratio: 1;
  fill: #a9a9a9;
  width: clamp(160px, 22vmin, 300px);
}
/*# sourceMappingURL=index.e9ae7b25.css.map */
