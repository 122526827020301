html,
body {
  position: relative;
}

body {
  overflow: hidden auto;
  width: 100vw;

  @supports (min-height: 100dvh) {
    min-height: 100dvh;
  }

  @supports not (min-height: 100dvh) {
    min-height: 100vh;
  }
}
